import { configure } from "vee-validate";
import { setLocale } from "@vee-validate/i18n";
import { localize } from "@vee-validate/i18n";
import pt_BR from "@vee-validate/i18n/dist/locale/pt_BR.json";

export function initVeeValidate() {
  // Updating default vee-validate configuration
  configure({
    generateMessage: localize({
      pt_BR,
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });
  console.log(setLocale("pt_BR"));
}
