import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/Dashboard.vue"),
      },
      {
        path: "/campanhas",
        name: "campanhas",
        component: () => import("@/components/RouterView.vue"),
        redirect: "/campanhas/lista",
        children: [
          {
            path: "lista",
            name: "campanhas-lista",
            component: () => import("@/pages/campanhas/List.vue"),
          },
          {
            path: "add",
            name: "campanhas-add",
            component: () => import("@/pages/campanhas/Add.vue"),
          },
          {
            path: "edit/:id",
            name: "campanhas-edit",
            component: () => import("@/pages/campanhas/Edit.vue"),
          },
        ]
      },
      {
        path: "/disparo-manual",
        name: "disparo-manual",
        component: () => import("@/pages/disparo-manual/List.vue"),
      },
      {
        path: "/extrair-contatos",
        name: "extrair-contatos",
        component: () => import("@/pages/extrair-contatos/List.vue"),
      },
      {
        path: "/disparadores",
        name: "disparadores",
        component: () => import("@/pages/disparadores/List.vue"),
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () => import("@/pages/Perfil.vue"),
      },
      {
        path: "/usuarios",
        name: "usuarios",
        component: () => import("@/components/RouterView.vue"),
        redirect: "/usuarios/lista",
        children: [
          {
            path: "lista",
            name: "usuarios-lista",
            component: () => import("@/pages/usuarios/List.vue"),
          },
          {
            path: "add",
            name: "usuarios-add",
            component: () => import("@/pages/usuarios/Add.vue"),
          },
          {
            path: "edit/:id",
            name: "usuarios-edit",
            component: () => import("@/pages/usuarios/Edit.vue"),
          },
        ]
      },
      {
        path: "/leads",
        name: "leads",
        component: () => import("@/components/RouterView.vue"),
        redirect: "/leads/lista",
        children: [
          {
            path: "lista",
            name: "leads-lista",
            component: () => import("@/pages/leads/List.vue"),
          },
          {
            path: "add",
            name: "leads-add",
            component: () => import("@/pages/leads/Add.vue"),
          },
          {
            path: "edit/:id",
            name: "leads-edit",
            component: () => import("@/pages/leads/Edit.vue"),
          },
        ]
      },
      {
        path: "/contas",
        name: "contas",
        component: () => import("@/pages/contas/List.vue"),
      },

    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/pages/auth/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/pages/auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
